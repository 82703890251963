// --------

export const getItemsFromQuerySnapshot = querySnapshot => {
    return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
}

export const getItemFromQuerySnapshot = querySnapshot => {
    return { ...querySnapshot.data(), id: querySnapshot.id };
}

export const getItemsFromDatabaseQuerySnapshot = querySnapshot => {
    const items = []
    if (querySnapshot) {
        querySnapshot.forEach(doc => {
            const item = doc.val()
            item.id = doc.key
            items.push(item)
        })
        // const properties = Object.keys(querySnapshot);
        // properties.forEach(property => {
        //     const item = querySnapshot[property];
        //     item.id = property;
        //     items.push(item)
        // })
    }
    return items;
}
