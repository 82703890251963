import { getAuth } from 'firebase/auth';
import { lazy, Suspense, useState } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Home } from './Home/Home/Home';
import { NotFound } from './Home/NotFound/NotFound';
import { Layout } from './Layout/Layout/Layout';

import { AppContextProvider } from './Shared/AppContext';
import { isAdmin } from './Shared/Calculations';

const ReactLazyPreload = (importStatement) => {
    const component = lazy(importStatement);
    // component.preload = importStatement;
    // component.preload()
    return component;
};

const LazyDashboard = ReactLazyPreload(() => import('./Dashboard/Dashboard/Dashboard'));
const LazyMyBag = ReactLazyPreload(() => import('./MyBag/MyBag/MyBag'));
const LazySCCalendar = ReactLazyPreload(() => import('./SCCalendar/SCCalendar'));
const LazyStatisticsPage = ReactLazyPreload(() => import('./Statistics/StatisticsPage/StatisticsPage'));

const LazyPlay = ReactLazyPreload(() => import('./Play/Play/Play'));

const LazyScoreCards = ReactLazyPreload(() => import('./ScoreCards/ScoreCards/ScoreCards'));
const LazyScoreCard = ReactLazyPreload(() => import('./ScoreCards/ScoreCard/ScoreCard'));
const LazyScoreCardEdit = ReactLazyPreload(() => import('./ScoreCards/ScoreCardEdit/ScoreCardEdit'));
const LazyScoreCardHole = ReactLazyPreload(() => import('./ScoreCards/ScoreCardHole/ScoreCardHole'));
const LazyScoreCardPlay = ReactLazyPreload(() => import('./ScoreCards/ScoreCardPlay/ScoreCardPlay'));

const LazyMatches = ReactLazyPreload(() => import('./Matches/Matches/Matches'));
const LazyMatch = ReactLazyPreload(() => import('./Matches/Match/Match'));

const LazyCourse = ReactLazyPreload(() => import('./Courses/Course/Course'));
const LazyCourseHole = ReactLazyPreload(() => import('./Courses/CourseHole/CourseHole'));
const LazyCourses = ReactLazyPreload(() => import('./Courses/Courses/Courses'));

const LazyTournament = ReactLazyPreload(() => import('./Tournaments/Tournament/Tournament'));
const LazyTournamentEdit = ReactLazyPreload(() => import('./Tournaments/TournamentEdit/TournamentEdit'));
const LazyTournaments = ReactLazyPreload(() => import('./Tournaments/Tournaments/Tournaments'));

const LazyExport = ReactLazyPreload(() => import('./User/Export/Export'));
const LazyLogin = ReactLazyPreload(() => import('./User/Login/Login'));
const LazyProfile = ReactLazyPreload(() => import('./User/Profile/Profile'));
const LazyRegister = ReactLazyPreload(() => import('./User/Register/Register'));
const LazyUsers = ReactLazyPreload(() => import('./User/Users/Users'));

// ------------------------------------------------------------------------------------

const AppRoutes = () => {
    // ------------------------------------------------------------------------------------

    const [user, setUser] = useState();
    const [logStatus, setLogStatus] = useState('login-in');
    //    const [firstLogin, setFirstLogin] = useState(false)

    // ------------------------------------------------------------------------------------

    const RequireAuth = ({ children }) => {
        const location = useLocation();

        return logStatus === 'logged'
            ? children
            : logStatus === 'not-logged' ?
                <Navigate
                    to="/login"
                    replace
                    state={{ path: location.pathname }}
                /> : <></>;
    }

    const DoNotRequireAuth = ({ children }) => {
        const location = useLocation();

        if (location.pathname === '/' && logStatus === 'logged' /* && firstLogin */) {
            //            setFirstLogin(false);
            return <Navigate
                to="/dashboard"
                replace
            />
        }
        return children;
    }

    // ------------------------------------------------------------------------------------

    getAuth().onAuthStateChanged(user => {
        setUser(user);

        const newLogStatus = user ? 'logged' : 'not-logged';
/*         if (logStatus === 'login-in' && newLogStatus === 'logged') {
            setFirstLogin(true);
        }
 */        setLogStatus(newLogStatus);
    })

    const routes = [
        { requireAuth: false, exact: true, path: "/login", component: LazyLogin },
        { requireAuth: false, exact: true, path: "/register", component: LazyRegister },

        { requireAuth: true, exact: true, path: "/dashboard", component: LazyDashboard },

        { requireAuth: true, exact: true, path: "/play", component: LazyPlay },

        { requireAuth: true, exact: true, path: "/score-cards", component: LazyScoreCards },
        { requireAuth: true, exact: true, path: "/score-cards/:id", component: LazyScoreCard },
        { requireAuth: true, exact: true, path: "/score-cards/:id/edit", component: LazyScoreCardEdit },
        { requireAuth: true, exact: true, path: "/score-cards/:id/hole/:hole", component: LazyScoreCardHole },
        { requireAuth: true, exact: true, path: "/score-cards/:id/play", component: LazyScoreCardPlay },

        { requireAuth: true, exact: true, path: "/matches", component: LazyMatches },
        { requireAuth: true, exact: true, path: "/matches/:id", component: LazyMatch },

        { requireAuth: true, exact: true, path: "/tournaments", component: LazyTournaments },
        { requireAuth: true, exact: true, path: "/tournaments/:id", component: LazyTournament },
        { requireAuth: true, exact: true, path: "/tournaments/:id/edit", component: LazyTournamentEdit },

        { requireAuth: true, exact: true, path: "/statistics", component: LazyStatisticsPage },
        { requireAuth: true, exact: true, path: "/calendar", component: LazySCCalendar },

        { requireAuth: true, exact: true, path: "/profile", component: LazyProfile },
        { requireAuth: true, exact: true, path: "/export", component: LazyExport },
        { requireAuth: true, exact: true, path: "/my-bag", component: LazyMyBag },

        { requireAuth: true, exact: true, path: "/courses", component: LazyCourses },
        { requireAuth: true, exact: true, path: "/courses/:id", component: LazyCourse },
        { requireAuth: true, exact: true, path: "/courses/:id/tee/:tee/hole/:hole", component: LazyCourseHole },
    ];

    // ------------------------------------------------------------------------------------

    return (
        <AppContextProvider>
            <Router>
                <Layout>
                    <Suspense fallback={<div>Loading page...</div>}>
                        <Routes>
                            <Route path="/" exact element={<DoNotRequireAuth><Home /></DoNotRequireAuth>} />
                            {routes.map(({ path, exact, component: C, requireAuth }, key) => (
                                requireAuth ?
                                    <Route key={key} path={path} exact={exact} element={<RequireAuth><C /></RequireAuth>} />
                                    :
                                    <Route key={key} path={path} exact={exact} element={<C />} />
                            ))}
                            {isAdmin(user?.uid) && <>
                                <Route exact path="/users" element={<RequireAuth><LazyUsers /></RequireAuth>} />
                            </>}
                            <Route path="*" element={<NotFound />} />

                        </Routes>
                    </Suspense>
                </Layout>
            </Router>
        </AppContextProvider>
    )
};

export default AppRoutes;
