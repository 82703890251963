import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './theme_1655193470052.css'; // Generated with https://themestr.app

import { onCLS, onFID, onLCP } from 'web-vitals';
import { initializeApp } from 'firebase/app';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import AppRoutes from './routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCEBb6p0TAhiCYSYbiUu-5WN0B01CkrS70",
    authDomain: "golf-scores-5dd4a.firebaseapp.com",
    projectId: "golf-scores-5dd4a",
    storageBucket: "golf-scores-5dd4a.appspot.com",
    messagingSenderId: "1099041374126",
    appId: "1:1099041374126:web:7b3ac763327ecad62b523f",
    measurementId: "G-2VM2XCWTZY",
    databaseURL: "https://golf-scores-5dd4a-default-rtdb.europe-west1.firebasedatabase.app/",
};

initializeApp(firebaseConfig);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

const root = createRoot(document.getElementById('root'))
root.render(<AppRoutes />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
onCLS((cls) => { console.log('CLS:', cls); });
onFID((fid) => { console.log('FID:', fid); });
onLCP((lcp) => { console.log('LCP:', lcp); });
