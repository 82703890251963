/* eslint-disable react-hooks/exhaustive-deps */
import { getAuth, signOut } from '@firebase/auth';
import { Timestamp } from '@firebase/firestore';
import { equalTo, getDatabase, off, onValue, orderByChild, push, query, ref, remove, set, startAfter, update } from "firebase/database";
import React, { useEffect, useRef, useState } from 'react';
import { playModes, scoringModes } from './Config';
import { getItemsFromDatabaseQuerySnapshot } from './Utils';

export const AppContext = React.createContext({});

export const AppContextProvider = ({ children }) => {
    const [user, setUser] = useState();
    const [userData, setUserData] = useState();
    const [courses, setCourses] = useState();
    const [scoreCards, setScoreCards] = useState();
    const [scoreCardsInMatches, setScoreCardsInMatches] = useState();
    const [tournaments, setTournaments] = useState();
    const [users, setUsers] = useState();
    const [matches, setMatches] = useState();

    // ---------------------------------------------------------------

    const logoff = async () => {
        return signOut(getAuth());
    }

    const updateMatch = async theMatch => {
        const newMatch = {
            ...theMatch,
            ...{
                modifiedDate: Timestamp.fromDate(new Date()),
                date: Timestamp.fromDate(theMatch.date),
                players: theMatch.scoreCards.map(scItem => scItem.userId)
            }
        };
        const newDoc = ref(getDatabase(), 'matches/' + theMatch.id);
        return await update(newDoc, newMatch);
    }

    const updateScoreCard = (theScoreCard, callback) => {
        const newScoreCard = {
            ...theScoreCard,
            ...{
                modifiedDate: Timestamp.fromDate(new Date()),
                date: Timestamp.fromDate(theScoreCard.date),
            }
        };
        const url = 'scoreCards/' + newScoreCard.id;
        const newDoc = ref(getDatabase(), url);
        update(newDoc, newScoreCard).then(result => {
            console.log("Updated score card");
            if (callback) {
                callback(newScoreCard)
            }
        }, error => {
            console.log(error);
        });
    }


    const addScoreCard = async (scoreCardInitData, callback) => {
        const baseScoreCard = {
            date: Timestamp.fromDate(new Date()),
            event: '',
            courseName: '',
            courseId: '',
            teeId: '',
            holeCount: 0,
            holes: [],
            shotCount: 0,
            stableford: 0,
            handicap: 0,
            handicapPlay: 0,
            handicapGolf: +appContext.userData.handicapGolf,
            handicapPitchAndPutt: +appContext.userData.handicapPitchAndPutt,
            isCompetition: false,
            notes: '',
            greenFee: 0,
            usedBuggy: false,
            userId: appContext.user.uid,
            creatorId: appContext.user.uid
        }
        const newScoreCard = { ...baseScoreCard, ...scoreCardInitData };

        let newDocRef = ref(getDatabase(), "scoreCards");
        const newDoc = await push(newDocRef, newScoreCard);
        newScoreCard.id = newDoc.key;
        return newScoreCard
    }

    const deleteScoreCard = async (scoreCard, callback) => {
        const scoreCardDoc = ref(getDatabase(), 'scoreCards/' + scoreCard.id);
        await remove(scoreCardDoc);
        if (callback) {
            callback(scoreCard)
        }
    }

    const addMatch = async matchInitData => {
        const date = Timestamp.fromDate(new Date());
        const theMatch = {
            date: date,
            creationDate: date,
            modifiedDate: date,
            courseId: '',
            tournamentId: '',
            isCompetition: false,
            notes: '',
            scoreCards: [],
            creatorId: appContext.user.uid,
            playMode: playModes[0],
            scoringMode: scoringModes[0],
            userId: appContext.user.uid,
        }
        const newMatch = { ...theMatch, ...matchInitData };

        let newDocRef = ref(getDatabase(), 'matches');
        let newDoc = await push(newDocRef, newMatch)
        newMatch.id = newDoc.key;
        return newMatch
    }

    const deleteMatch = async (theMatch, callback) => {
        const matchDoc = ref(getDatabase(), '/matches/' + theMatch.id);
        remove(matchDoc).then(() => {
            if (callback) {
                callback(matchDoc)
            }
        });
    }

    const updateCourse = async (theCourse, callback) => {

        const newCreationDate = theCourse.creationDate || new Date();
        const newCourse = {
            creationDate: Timestamp.fromDate(newCreationDate),
            lastModifiedDate: Timestamp.fromDate(new Date()),
            name: theCourse.name,
            country: theCourse.country || '',
            province: theCourse.province || '',
            city: theCourse.city || '',
            tees: theCourse.tees,
            notes: theCourse.notes || '',
            latitude: theCourse.latitude || 0,
            longitude: theCourse.longitude || 0,
            zoom: theCourse.zoom || 0,
            bearing: theCourse.bearing || 0
        };
        let newDoc;
        if (!theCourse.id) {
            newDoc = ref(getDatabase(), 'courses');
        } else {
            newDoc = ref(getDatabase(), 'courses/' + theCourse.id);
        }
        set(newDoc, newCourse).then(newDoc => {
            if (callback) {
                callback(newDoc)
            }
        });
    }

    const deleteCourse = async (theCourse, callback) => {
        const scoreCardDoc = ref(getDatabase(), 'courses/' + theCourse.id);
        remove(scoreCardDoc).then(() => {
            if (callback) {
                callback()
            }
        });
    }

    const updateUserData = async (theUserData, callback) => {
        const newDoc = ref(getDatabase(), 'users/' + appContext.user.uid);
        set(newDoc, theUserData).then(theDoc => {
            if (callback) {
                callback(theUserData)
            }
        });
    }

    const updateTournament = async (theTournament, callback) => {
        const newDoc = ref(getDatabase(), 'tournaments/' + theTournament.id);
        set(newDoc, theTournament).then(theTournament => {
            if (callback) {
                callback(theTournament)
            }
        });
    }

    const deleteTournament = async (theTournament, callback) => {
        const newDoc = ref(getDatabase(), 'tournaments/' + theTournament.id);
        remove(newDoc).then(() => {
            if (callback) {
                callback(newDoc)
            }
        });
    }

    // ---------------------------------------------------------------

    const appContext = useRef({}).current;
    appContext.getScoreCardsPath = () => { return '/scoreCards'; }

    appContext.user = user;
    appContext.scoreCards = scoreCards;
    appContext.scoreCardsInMatches = scoreCardsInMatches;
    appContext.courses = courses;
    appContext.matches = matches;
    appContext.tournaments = tournaments;
    appContext.users = users;
    appContext.userData = userData;
    appContext.logoff = logoff;

    appContext.getCourseById = id => courses?.find(c => c.id === id);
    appContext.getScoreCardById = id => scoreCards?.find(sc => sc.id === id);
    appContext.getMatchById = id => matches?.find(m => m.id === id);
    appContext.getTournamentById = id => tournaments?.find(t => t.id === id);
    appContext.getUserById = id => users?.find(t => t.id === id);

    appContext.getScoreCardTee = (scoreCard) => {
        const course = appContext.getCourseById(scoreCard.courseId);
        return course ? course.tees.find(tee => (tee.id === scoreCard.teeId) || (tee.name === scoreCard.teeName)) : undefined;
    }

    appContext.addScoreCard = addScoreCard;
    appContext.updateScoreCard = updateScoreCard;
    appContext.deleteScoreCard = deleteScoreCard;

    appContext.addMatch = addMatch;
    appContext.updateMatch = updateMatch;
    appContext.deleteMatch = deleteMatch;

    appContext.updateCourse = updateCourse;
    appContext.deleteCourse = deleteCourse;

    appContext.updateTournament = updateTournament;
    appContext.deleteTournament = deleteTournament;

    appContext.updateUserData = updateUserData;

    // ---------------------------------------------------------------

    getAuth().onAuthStateChanged(newUser => {
        if (user !== newUser) {
            setUser(newUser);
        }
    });

    // ---------------------------------------------------------------

    useEffect(() => {
        if (!user) {
            setCourses();
            setMatches();
            setTournaments();
            setUsers()
            setUserData();
            return;
        }

        const scoreCardsQuery = query(ref(getDatabase(), 'scoreCards'), orderByChild('userId'), equalTo(user.uid));
        onValue(scoreCardsQuery, snapshot => {
            const newScoreCards = getItemsFromDatabaseQuerySnapshot(snapshot)
            newScoreCards.forEach(scoreCard => {
                scoreCard.date = new Date(scoreCard.date.seconds * 1000);
                scoreCard.modifiedDate = scoreCard.modifiedDate ? new Date(scoreCard.modifiedDate.seconds * 1000) : new Date();
            })
            const sortedScoreCards = newScoreCards.sort((a, b) => (a.date < b.date) ? 1 : (a.date > b.date) ? -1 : 0);
            setScoreCards(sortedScoreCards);
        })

        //        const scoreCardsInMatchesQuery = query(ref(getDatabase(), 'scoreCards'), orderByChild('userId'));
        const scoreCardsInMatchesQuery = query(ref(getDatabase(), 'scoreCards'), orderByChild('matchId'), startAfter(""));
        onValue(scoreCardsInMatchesQuery, snapshot => {
            const newScoreCards = getItemsFromDatabaseQuerySnapshot(snapshot)
            newScoreCards.forEach(scoreCard => {
                scoreCard.date = new Date(scoreCard.date.seconds * 1000);
            })
            setScoreCardsInMatches(newScoreCards);
        })

        const coursesQuery = query(ref(getDatabase(), 'courses'));
        onValue(coursesQuery, snapshot => {
            const newCourses = getItemsFromDatabaseQuerySnapshot(snapshot)
            newCourses.forEach(course => {
                course.creationDate = new Date(course.creationDate.seconds * 1000);
                course.lastModifiedDate = new Date(course.lastModifiedDate.seconds * 1000);
            })
            setCourses(newCourses);
        })

        const matchesQuery = query(ref(getDatabase(), 'matches'));
        onValue(matchesQuery, snapshot => {
            const newMatches = getItemsFromDatabaseQuerySnapshot(snapshot)
            newMatches.filter(match => match.creatorId === user.uid || (match.players && match.players.includes(user.uid))).forEach(match => {
                match.date = new Date(match.date.seconds * 1000);
            })
            const sortedMatches = newMatches.sort((a, b) => (a.date < b.date) ? 1 : (a.date > b.date) ? -1 : 0);
            setMatches(sortedMatches);
        })

        const usersQuery = query(ref(getDatabase(), 'users'));
        onValue(usersQuery, snapshot => {
            const newUsers = getItemsFromDatabaseQuerySnapshot(snapshot)
            setUsers(newUsers);

            const userData = newUsers.find(u => u.id === user.uid);
            setUserData({
                ...userData,
                id: userData.id,
                firstName: userData.firstName,
                lastName: userData.lastName,
                handicap: userData.handicap || 36,
                handicapPitchAndPutt: userData.handicapPitchAndPutt || 36,
                email: userData.email
            });
        })

        const tournamentsQuery = query(ref(getDatabase(), 'tournaments'));
        onValue(tournamentsQuery, snapshot => {
            const newTournaments = getItemsFromDatabaseQuerySnapshot(snapshot);
            const filteredTournaments = newTournaments.filter(tournament => tournament.players?.find(player => player.userId === user.uid) || user.uid === tournament.organizer?.id);
            setTournaments(filteredTournaments);
        })

        return () => {
            off(scoreCardsQuery);
            off(coursesQuery);
            off(matchesQuery);
            off(tournamentsQuery);
            off(usersQuery);
        }
    }, [user]);

    // ---------------------------------------------------------------

    return (
        <AppContext.Provider value={{ appContext }}>
            {children}
        </AppContext.Provider>
    )
}
